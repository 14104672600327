import {H} from "../helpers/H";
import {ClientSite} from "./models";
import {PdmRegDef} from "./PdmRegDef";
import {Pdm} from "./Pdm";
import * as mexp from 'math-expression-evaluator';
import {SiteService} from "../services/site.service";
import {AppService} from "../services/app.service";
import {Optimise} from "../helpers/Optimise";
import {MomentInput, MomentInputObject} from "moment";

export class PdmDataRow {
    public static VAR_EX_DTOT = "dTot";
    public static VAR_EX_DTOT_IMPORT = "dTotImpDe";
    public static VAR_EX_DTOT_EXPORT = "dTotExpDe";
    override = true;
    uid: string;
    date: string;
    data_key: number;
    date_prev: string;
    dist: number;
    days: number;
    releve_num: number;
    releve_year: number;

    metas: any = {};
    data = {};
    c_tot_paid: number;

    tots = {};
    vars_meteo: {};
    vars_meteo_obj: PdmDataMeteo;
    vars_horaire = {};
    ve: number;
    exported: PdmDataRowExported = new PdmDataRowExported();
    vars_reg = {};

    ts_calced = 0;
    ts_assigned = 0;
    ts: number;

    is_in_creation = false;

    get md5() {
        this.tots = Optimise.sortObjectKeys(this.tots);
        this.exported = Optimise.sortObjectKeys(this.exported) as PdmDataRowExported;
        this.vars_reg = Optimise.sortObjectKeys(this.vars_reg);
        this.vars_horaire = Optimise.sortObjectKeys(this.vars_horaire);
        this.vars_meteo = Optimise.sortObjectKeys(this.vars_meteo);
        this.data = Optimise.sortObjectKeys(this.data);
        return H.getMd5(JSON.stringify(this));
    }

    get rowDate() {
        return new Date(this.date);
    }

    get rowMinDate() {
        return H.addDaysToDate(new Date(this.date_prev), 15);
    }

    /*
    if argv.regs is provided populateTotFields() is called and this.data_global is populated. OTHERWISE: NO
     */
    constructor(dynProps: any = {}, regs: PdmRegDef[] = [], withMetas = true) {
        Object.keys(dynProps).forEach(key => this[key] = dynProps[key]);
        this.ts = 0;
        this.populateFirstNumDaysAndYear();
        // ****
        if (dynProps['exported'])
            this.exported = new PdmDataRowExported(dynProps['exported']);

        if (!withMetas)
            this.metas = {};
        else {
            // this.metas = Optimise.sortArrayByKeyVal(this.metas, 'ts');
        }
        if (regs.length > 0)
            this.extractVarExplFromData(regs);

        if (this.ve) this.ve = Number(this.ve);
        this.is_in_creation = false;
    }

    /*
    functions getters
     */
    isCalc(reg: PdmRegDef, field: string): boolean {
        return reg && reg[field + "_conf"] && reg[field + "_conf"].data_entry
            && reg[field + "_conf"].data_entry.includes('CALC_');
    }

    isMacro(reg: PdmRegDef, field: string): boolean {
        return reg && reg[field + "_conf"] && reg[field + "_conf"].data_entry && reg[field + "_conf"].data_entry === "CALC_MACRO";
    }

    isValueManualyEdited(field: string, regNum: number) {
        return (this.metas && this.metas[field] && this.metas[field].includes('EDIT'))
    }

    isFieldDefined(prefix: string, regNum: number) {
        const fName = prefix + String(regNum);
        const vFName = this.data[fName];
        if (vFName !== undefined && !isNaN(vFName))
            return true;
        return false;
    }

    isFieldMapped(prefix: string, regNum: number) {
        const fName = prefix + String(regNum);
        const inputType = this.metas[fName];
        return inputType && (inputType.startsWith('MAP') || inputType.startsWith('SYNC'));
    }

    /*
    internal proccessing
     */
    populateFirstNumDaysAndYear(prevDate = null) {
        if (prevDate) {
            //if (this.date_prev === prevDate) return;// if prevDate is the same, no need to run // remove until data integrity proved
            this.date_prev = prevDate;
        }
        const dateObj = new Date(this.date);
        const prevDateObj = new Date(this.date_prev);

        this.dist = H.getDayNum(dateObj);
        if (this.dist < 14) {
            console.log("Overriding releve_num = 12 ", this.dist);
            this.releve_num = 12;
            this.releve_year = dateObj.getFullYear() - 1;
        } else this.releve_year = dateObj.getFullYear();

        if (this.date_prev) {
            this.days = (dateObj.getTime() - prevDateObj.getTime()) / 1000 / 60 / 60 / 24;
        } else {
            this.dist = H.getDayNum(dateObj);
        }
        if (this.dist >= 14 && this.dist < 41) {
            this.releve_num = 1;
            console.log("Overriding releve_num =1 ", this.dist);
            // this.dist = this.days; populates in // countReleveAndSetNum()
        }
        // in last resort => should warn
        if (!this.releve_num) {
            this.releve_num = Math.round(this.dist / 30);
        }

        if (this.releve_num && this.releve_year)
            this.data_key = Number(Optimise.keyFromYearMonth(this.releve_year, this.releve_num));
        else {
            console.error("Releve year or Num is null", this.releve_num, this.releve_year, this.days, "Prev: ", this.date_prev, " Curr:", this.date, this);
        }

    }

    countReleveAndSetNum(statefulIterator: PdmRowStatefulIterator) {
        if (this.override = true) {
            /// TODO: warn when dist is null and use this method to scan data and verify data, should not implicitly edit
            return;
        }
        if (this.releve_num === 1) {
            statefulIterator.releve_num = 1;
            statefulIterator.dist = this.days;
        } else {
            statefulIterator.releve_num++;//accumulator
            statefulIterator.dist += this.days;//accumulator
            this.releve_num = statefulIterator.releve_num;
        }
        this.dist = statefulIterator.dist;
    }

    setRowDate(momentObj: any) {
        this.date = H.dateToStrMysql(new Date(momentObj));
        this.populateFirstNumDaysAndYear();
    }

    /*
    Mark change for saving
     */
    setTs() {
        this ['ts'] = H.unixTs();
    }

    reset() {
        this.data = {};
        this.c_tot_paid = 0;
        this.exported = null;
        this.vars_meteo = {};
        this.vars_reg = {};
        this.vars_horaire = {};
        this.tots = {};
        this.metas = {};
        this.vars_meteo_obj = null;
        this.ve = 0;
        this.ts = 0;
        this.ts_calced = 0;
        this.ts_assigned = 0;
    }

    getFieldValue(prefix: string, regNum: number): number {
        const fName = prefix + String(regNum);
        return Number(this.data[fName]);
    }

    setFieldValue(reg: PdmRegDef, field: string, value: number, metas: string = '') {
        if (isNaN(value)) return;
        const fName = field === 'cTotPaid' ? field : field + String(reg.num);
        if (this.data[fName] === value) return;
        // const chg: RegFieldChangeLog = new RegFieldChangeLog(field, reg, this.data[fName], value, metas, localStorage.getItem('email2'));

        // no overwrite edited fields unless calc fields
        if (!this.metas) this.metas = {};
        if (this.isCalc(reg, field)) {
            this.data[fName] = value;
        } else {
            // only allow seting value that wasnt manually overrided
            if (this.isValueManualyEdited(field, reg.num)) {
                console.error("setFieldValue-Cant set manually edited value", fName, value, metas);
                return;
            }
            this.data[fName] = value;
            if (field === 'cTotPaid') this.c_tot_paid = value;//stored in both c_tot_paid and data
            this.ts_assigned = H.unixTs();
        }
        this.metas[fName] = metas;
        this.setTs();
    }

    /*
    CALC Fields
     */
    calcRow(regs: PdmRegDef[], pdm: Pdm, force = false) {//bootstrap all calc
        regs.forEach(reg => {
            this.calcMacro(reg, 'd');
            this.calcMacro(reg, 't');
            this.calcMacro(reg, 'c');

            this.calcD(reg);
            this.calcT(reg);
            this.calcC(reg);
            this.ts_calced = H.unixTs();
        });
        this.populateTotFields(regs, pdm, force);
    }

    calcT(reg: PdmRegDef) {
        const regNum = reg.num;
        if (reg.calced_field === 't') {
            if (this.isFieldDefined('d', regNum) && this.isFieldDefined('c', regNum)) {
                const d = this.getFieldValue('d', regNum);
                const c = this.getFieldValue('c', regNum);
                if (d > 0)
                    this.setFieldValue(reg, 't', c / d, 'CALC:' + c + " / " + d);
            }
        }
    }

    calcD(reg: PdmRegDef): void {
        let deltaIndex = 0;
        let metas = "";
        const regNum = reg.num;
        if (reg.calced_field === 'd') {
            if (this.isFieldDefined('i', regNum) && this.isFieldDefined('p', regNum)) {
                const k = this.getFieldValue('k', regNum) || 1;
                const i = this.getFieldValue('i', regNum);
                const p = this.getFieldValue('p', regNum);
                deltaIndex = i - p;
                metas = 'CALC:STD:' + k + " x " + deltaIndex;

                // in case of cpt replacement
                if (this.isFieldDefined('f', regNum) && this.isFieldDefined('s', regNum)) {
                    const f = this.getFieldValue('f', regNum);// final index of replaced cpt
                    const s = this.getFieldValue('s', regNum);// start val of new cpt
                    deltaIndex = (i - s) + (f - p);
                    metas = 'CALC:REPLACED:' + k + " x " + deltaIndex;
                }
                const d = k * deltaIndex;
                this.setFieldValue(reg, 'd', d, metas);
            }
        }
    }

    calcC(reg: PdmRegDef): void {
        const regNum = reg.num;
        if (reg.calced_field === 'c') {
            const d = this.getFieldValue('d', regNum);
            const t = this.getFieldValue('t', regNum);
            const days = Number(this.days);

            if (this.isFieldDefined('t', regNum)) {

                if (reg.c_type === PdmRegDef.TYPE_C_EO && this.isFieldDefined('d', regNum)) {
                    this.setFieldValue(reg, 'c', d * t, 'CALC:' + d + " x " + t);
                }
                if (reg.c_type === PdmRegDef.TYPE_C_ED && this.isFieldDefined('d', regNum)) {
                    this.setFieldValue(reg, 'c', d * days * t, 'CALC:' + d + 'x' + days + " x " + t);
                }
                if (reg.c_type === PdmRegDef.TYPE_C_DO) {
                    this.setFieldValue(reg, 'c', days * t, 'CALC:' + days + " x " + t);
                }
                if (reg.c_type === PdmRegDef.TYPE_C_MN) {
                    // C is entered manually or mapped
                }
                if (reg.c_type === PdmRegDef.TYPE_C_MC) {

                }
            }
        }
    }

    calcMacro(reg: PdmRegDef, prefix: string) {
        if (!this.isMacro(reg, prefix))
            return;

        const fieldsPrefixes = ['i', 'p', 'k', 'd', 't', 'c'];
        let macroStored = (reg.getMacro(prefix)).toLowerCase();
        let macroEqWithData = macroStored + " ";
        let evaluatedMacro = 0;
        let foundPrefixs = '';

        /*
        Replace regFields with their respective values
         */
        let tokensCount = 0;
        let tokensDefinedCount = 0;
        for (let i = 50; i >= 0; i--) {
            fieldsPrefixes.forEach(prfx => {
                const fName = prfx + i;
                foundPrefixs += ":" + fName;
                if (macroStored.includes(fName)) {
                    tokensCount++;
                    if (this.isFieldDefined(prfx, i)) {
                        tokensDefinedCount++;
                        const v = this.getFieldValue(prfx, i);
                        macroEqWithData = macroEqWithData.replaceAll(fName, String(v));
                    } else {
                        macroEqWithData = macroEqWithData.replaceAll(fName, '0');
                    }
                }
            });
        }

        //  console.log('calcMacro:' + prefix, macroStored, macroEqWithData, this._globalFieldsData, this._globalFieldsConf, globalFieldsList);
        try {
            evaluatedMacro = mexp.eval(macroEqWithData);
            if (tokensDefinedCount > 0)
                this.setFieldValue(reg, prefix, evaluatedMacro, 'MACRO:' + macroStored);
            else {
                delete this.data[prefix + reg.num];
            }
        } catch (e) {
            console.log(e.message);
            console.log("calcMacro:ERR", macroStored + "|", macroEqWithData + "|", foundPrefixs, this);
            console.error(e);
        }

    }

    /*
    CALC TOTAL
     */
    populateTotFields(regs: PdmRegDef[], pdm: Pdm = null, force = false) {
        const totFields = Pdm.getTotFields(regs, this);
        const prePopulateHash = this.md5;
        this.tots = {};

        /*
        Object.keys(totFields).forEach(k => {
            this.tots[k] = totFields[k];
        });*/
        this.tots = totFields;
        // populate var_regs for derniere with labels
        this.extractVarExplFromData(regs);
        if (pdm) {
            this.exported = new PdmDataRowExported({});
            this.ve = 0;
            if (pdm.ve1 && this.tots[pdm.ve1] !== undefined) {
                if (this.tots[pdm.ve1] !== null && this.tots[pdm.ve1] !== undefined)
                    this.ve = this.tots[pdm.ve1];
                //if (pdm.is_pci) this.ve = 0.9 * this.ve;
                this.ve = Math.floor(this.ve * 1000) / 1000;
                let ve2Reg: PdmRegDef = null;
                const regNumForVE2 = pdm.ve2.replace('reg', '');
                if (Number(regNumForVE2) > 0) {
                    regs.forEach(r => {
                        if (r.num === Number(regNumForVE2)) ve2Reg = r;
                        if (r.is_ctot_paid) this.c_tot_paid += Number(this.data['c' + r.num]) || 0;//here calc cTotPaid
                    });
                }
                ///TODO: remove this line
                // if (this.data['cTotPaid'])    this.tots['cTot'] = this.data['cTotPaid'];
                //console.log("populateTotFields: VE2:Reg: ", ve2Reg, regNumForVE2);
                const veCostKEy = pdm.ve1.replace("dT", "cT");
                const ve2d = pdm.ve2.replace("reg", "d");
                const ve2c = pdm.ve2.replace("reg", "c");

                this.exported.ve1_d = this.ve;
                if (this.tots[veCostKEy] !== null && this.tots[veCostKEy] !== undefined)
                    this.exported.ve1_c = this.tots[veCostKEy];

                if (pdm.ve2 !== undefined && pdm.ve2 !== null) {
                    this.exported.ve2_d = 0;
                    this.exported.ve2_c = 0;
                    this.exported.ve2Label = ve2Reg ? ve2Reg.t_type : "";
                }
                if (this.data[ve2d] !== null && this.data[ve2d] !== undefined)
                    this.exported.ve2_d = this.data[ve2d] || 0;
                if (this.data[ve2c] !== null && this.data[ve2c] !== undefined)
                    this.exported.ve2_c = this.data[ve2c] || 0;

                if (this.tots['dTotImpDpTnn'] !== null && this.tots['dTotImpDpTnn'] !== undefined)
                    this.exported.pt_d = this.tots['dTotImpDpTnn'] || 0;
                if (this.tots['cTotImpDpTnn'] !== null && this.tots['cTotImpDpTnn'] !== undefined)
                    this.exported.pt_c = this.tots['cTotImpDpTnn'] || 0;

                if (this.tots['dTotImpDrTnn'] !== null && this.tots['dTotImpDrTnn'] !== undefined)
                    this.exported.er_d = this.tots['dTotImpDrTnn'] || 0;
                if (this.tots['cTotImpDrTnn'] !== null && this.tots['cTotImpDrTnn'] !== undefined)
                    this.exported.er_c = this.tots['cTotImpDrTnn'] || 0;

            }
        }
        // use MD5 to check changes, dont  mark for saving (ts) if populate total havent change data
        const postPopulateHash = this.md5;
        //console.log("populateTotFields", prePopulateHash, postPopulateHash, force);
        if (prePopulateHash !== postPopulateHash || force) {
            this.setTs();
        } //else console.log("SAME HASH AFTER CALC");
        // console.log("hashs", prePopulateHash, postPopulateHash, '|', prePopulateHash === postPopulateHash, oldTots, this.tots,this.vars_reg);
        //console.log("getTotFields: ", totFields, regs, this);
    }

    extractVarExplFromData(regs: PdmRegDef[]) {
        const retVal = {};
        const explVars = [];
        const explVarsLabel = {};
        if (regs && regs.length > 0)
            regs.forEach(reg => {
                // keep varexpl config global (targeting varexpl label display in model generation plot)
                if (reg.type === PdmRegDef.REG_TYPE_VAR) {
                    explVars.push('d' + reg.num);
                    explVarsLabel['d' + reg.num] = reg.label;
                }
            });

        Object.keys(this.data).forEach(key => {
            if (explVars.includes(key)) {
                retVal[key] = this.data[key];
                retVal[key + "_lb"] = explVarsLabel [key];
            }
        });
        this.vars_reg = retVal;
        //console.log("getVarsFor:" + field, retVal, this);
    }

    getJsonToSave(clientSite: ClientSite, pdm: Pdm, regs: PdmRegDef[]) {
        return {
            date: this.date,
            data_key: this.data_key,
            date_prev: this.date_prev,
            ve: this.ve,
            data: this.data,
            c_tot_paid: this.c_tot_paid,
            metas: this.metas,
            tots: this.tots,
            exported: this.exported,
            dist: this.dist,
            days: this.days,
            ts_assigned: this.ts_assigned,
            ts_calced: this.ts_calced,
            releve_num: this.releve_num,
            releve_year: this.releve_year,
            vars_reg: this.vars_reg,//in case reg def change during session
            vars_meteo: this.vars_meteo,//in case reg def change during session
            vars_horaire: this.vars_horaire,//in case reg def change during session
            uid: this.uid,///TODO: update this line, better uid
            uid_pdm: pdm.uid,
            uid_site: clientSite.uid,
            uid_client: clientSite.uid_client
        };
    }

    clearMetasOfField(field: string, regNum: number) {

        const metasToRemove = this.metas.filter(m => (m.f === field && m.r === regNum));
        this.metas = this.metas.filter(item => !metasToRemove.includes(item));
    }

}

export class PdmDataRowExported {
    public static EREAC_COST = "er_c";
    public static EREAC_CONS = "er_d";
    public static POINTE_COST = "pt_c";
    public static POINTE_CONS = "pt_d";
    public static VE1_COST = "ve1_c";
    public static VE1_CONS = "ve1_d";
    public static VE2_COST = "ve2_c";
    public static VE2_CONS = "ve2_d";
    ve2Label: string;
    er_c: number;
    er_d: number;
    pt_c: number;
    pt_d: number;
    ve1_c: number;
    ve1_d: number;
    ve2_c: number;
    ve2_d: number;

    constructor(dynProps: any = {}) {
        Object.keys(dynProps).forEach(key => this[key] = dynProps[key]);
    }
}

export class RegFieldXlsMappingConfigItem {
    targetField: string;
    xls_file: string;
    panelName: string;
    colIndex = 0;
    initCount = 0;
    rowIndexStart = 0;
    rowDateStart: string;
    rowDateEnd: string;
    colLabel: string;
    colUnit: string;

    getStr() {
        const chunks = [];
        chunks.push(this.xls_file);
        chunks.push(this.panelName);
        chunks.push(this.colIndex);
        chunks.push(this.colLabel);
        chunks.push(this.rowDateStart);
        chunks.push(this.rowDateEnd);
        return "MAP:" + chunks.join('|');
    }

    reset() {
        this.initCount = 0;
        this.colIndex = 0;
        this.rowIndexStart = 0;
        this.colLabel = '';
        this.colUnit = '';
        this.rowDateStart = '';
    }
}

export class PdmDataMeteo {
    i: number;
    m1: number;
    m2: number;
    m3: number;

    _m1: number;
    _m2: number;
    _m3: number;

    days: number;
    year: number;
    date: string;
    date_curr: string;
    date_prev: string;
    temps: Map<string, number> = new Map<string, number>();
    djc_EP: number = 0;
    djc_SI: number = 0;
    djf_SI: number = 0;

    constructor(dynProps: any = {}) {
        if (!dynProps) return;
        Object.keys(dynProps).forEach(key => this[key] = dynProps[key]);
        if (dynProps['temps']) {
            this.temps = new Map<string, number>(Object.entries(dynProps['temps']));
            if (this.temps && this.temps && this.temps.size > 0) {
                this.djc_SI = this.getDJ('c', 12, 20);
                this.djf_SI = this.getDJ('fc', 20, 20);
            }
        }
    }

    getDJ(type: string, base: number, ref: number): number {
        let retVal = 0;
        if (this.temps && this.temps && this.temps.size > 0) {
            this.temps.forEach((v, k) => {
                if (type.toLowerCase() === 'c') {
                    if (v < base) retVal += (ref - v);
                }
                if (type.toLowerCase() === 'f') {
                    if (v > base) retVal += (v - ref);
                }
            });
        }
        return retVal;
    }
}

export class PdmRowStatefulIterator {
    // Object is neccessary to pass vars by reference
    prevIndex = 0;
    prevDate = '';
    releve_num = 0;
    dist = 0;
}


/*
getVarsFor(field: string, regs: PdmRegDef[] = null) {
    const retVal = {};
    const explVars = [];
    const explVarsLabel = {};
    if (regs && regs.length > 0)
        regs.forEach(reg => {
            // keep varexpl config global (targeting varexpl label display in model generation plot)
            if (reg.reg_type_sub === PdmRegDef.REG_SUB_TYPES_VAR_EXPL) {
                explVars.push('d' + reg.num);
                explVarsLabel['d' + reg.num] = reg.reg_label;
            }
        });

    Object.keys(this.data).forEach(key => {
        if (key === 'date') this.date = this.data[key];
        if (field === "metas") {
            if (key.startsWith("_")) {
                const newKey = key.replace("_", '');
                retVal[newKey] = this.data[key];
                delete this.data[key];
            }
        }
        if (field === "tots" && key !== 'cTotPaid') {
            if (key.startsWith("cTot") || key.startsWith("dTot")) {
                retVal[key] = this.data[key];
                delete this.data[key];
            }
        }
        if (field === "data") {
            if (/\d/.test(key) || key === 'cTotPaid' || key === 'date') {
                retVal[key] = this.data[key];
            }
        }
        if (field === "vars_reg") {
            if (explVars.includes(key)) {
                retVal[key] = this.data[key];
                retVal[key + "_lb"] = explVarsLabel [key];
            }
        }
    });
    this[field] = retVal;
    //console.log("getVarsFor:" + field, retVal, this);
    return retVal;
}*/
