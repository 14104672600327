import {AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from "@angular/core";

import {SiteService} from "../../shared/services/site.service";
import {
    Plot
} from "../../shared/models/models";
import {BaseComponent} from "../../shared/BaseComponent";
import {AppService} from "../../shared/services/app.service";
import {DomSanitizer} from "@angular/platform-browser";
import {MatSnackBar} from "@angular/material/snack-bar";
import {ModelManager} from "../../shared/helpers/ModelManager";
import {H} from "../../shared/helpers/H";
import {K} from "../../shared/models/K";
import * as Highcharts from 'highcharts';

@Component({
    selector: 'statmens-previsions',
    template: `
        <div fxLayout="row">
            <div fxFlex>
                <mat-card class="m-4 p-8">
                    <h3>Consommation correlée de {{ site.year }}</h3>
                    <div fxLayout="row" fxLayoutAlign="space-between center" class="ref-year-choser">
                        <div>Selon modèle de l'année de réf {{ site.refYear }}</div>
                        <div>
                            <ng-container *ngFor="let refY of site.availableYearsInRows">
                                <button *ngIf="refY<site.year" mat-raised-button color="primary" class="btn-xs"
                                        [class.active]="site.selectedConfigForPdmAndYear.year_ref==refY"
                                        (click)="updateRefYear(refY)">{{ refY }}
                                </button>
                            </ng-container>
                        </div>
                    </div>
                    <table class="bloc-statmens-table " *ngIf="modelManager&&modelManager.currYearCalcedRows.length">
                        <tr class="row-head" *ngFor="let row of modelManager.currYearCalcedRows">
                            <ng-container *ngIf="row.releve_num===2">
                                <td>{{ row.releve_num }}</td>
                                <td *ngFor="let v of row.var_meteo|keyvalue" [class.chosen]="chosenVars.includes(v.key)">
                                    {{ v.key }}
                                </td>
                                <td *ngFor="let v of row.var_extra|keyvalue" [class.chosen]="chosenVars.includes(v.key)">
                                    {{ row.var_labels[v.key] }}
                                </td>
                                <td *ngFor="let v of row.var_regs|keyvalue" [class.chosen]="chosenVars.includes(v.key)">
                                    {{ row.var_labels[v.key] }}
                                </td>
                                <td></td>
                                <td>VE</td>
                                <td>Ve-corr</td>
                                <td>Diff</td>
                            </ng-container>

                        </tr>
                        <tr *ngFor="let row of modelManager.currYearCalcedRows;let i=index;">
                            <td class="value">{{ row.releve_num }}</td>
                            <td class="value" *ngFor="let v of row.var_meteo|keyvalue">
                                {{ v.value|number }}
                            </td>
                            <td class="value" *ngFor="let v of row.var_extra|keyvalue">
                                {{ v.value|number }}
                            </td>
                            <td class="value" *ngFor="let v of row.var_regs|keyvalue">
                                {{ v.value|number }}
                            </td>
                            <td class="arrow-svg-holder">
                                <img width="60px" src="assets/images/long-right-double-arrow.svg" alt="" class="mb-8">
                            </td>
                            <td class="value">
                                {{ row.ve.raw|number }}
                            </td>
                            <ng-container *ngIf="modelManager.derniere">
                                <td class="value">
                                    {{ modelManager.derniere[i]|number }}
                                </td>
                                <td class="value">
                                    {{ (row.ve.raw - modelManager.derniere[i])|number }}
                                </td>
                            </ng-container>
                        </tr>
                    </table>
                </mat-card>
            </div>
            <div fxFlex="750px">

                <mat-card class="m-4 p-8">
                    <h3 fxLayout="row" fxLayoutAlign="space-between center">
                        <span fxFlex>Modèle</span>
                        <button mat-raised-button class="btn-xs" color="primary" (click)="plotDerniere()">Calculer la derniere</button>
                    </h3>

                    <div fxLayout="row" class="model-summary">
                        <div class="p-4" *ngFor="let cv of site.selectedConfigForPdmAndREFYear.vars_used;let i=index">
                            <span *ngIf="site.selectedConfigForPdmAndREFYear.model[i]">
                                {{ site.selectedConfigForPdmAndREFYear.model[i]|number }} x 
                            </span>
                            <b>{{ varLabel(cv) }}</b>
                        </div>
                    </div>
                    <highcharts-chart [Highcharts]="Highcharts" [options]="chartOptions"></highcharts-chart>
                </mat-card>
            </div>
        </div>
    `,

    styleUrls: ['../../shared/components/shared-styles/shared.scss']
})
export class StatmensPrevisionsComponent implements OnInit, OnChanges {

    Highcharts: typeof Highcharts = Highcharts;
    chartOptions: Highcharts.Options;

    modelManager: ModelManager;
    chosenVars = [];

    constructor(public myapp: AppService, public site: SiteService, private snackBar: MatSnackBar, public sanitizer: DomSanitizer) {
        this.site.siteSpecificEventTriggerer.subscribe(eventName => {

            if (eventName === SiteService.PDMS_YEARLY_PLOT_DATA_CALCED) {
                this.bootstrap();
            }
            if (eventName === SiteService.PDMS_YEARLY_CONFIG_CHANGED) {
                this.bootstrap();
            }
            if (eventName === SiteService.PDMS_YEARLY_CONFIG_SELECTED) {
                this.bootstrap();
            }
        });
    }

    ngOnInit() {

    }

    bootstrap() {
        this.chosenVars = this.site.selectedConfigForPdmAndYear.vars_used;
        this.modelManager = new ModelManager(
            this.site.selectedPdmYearlyPlotDataMap,
            this.site.selectedConfigForPdmAndYear,
            this.site.selectedConfigForPdmAndREFYear,
        );

        console.log("StatmensPrevisions: ", this.site.year, this.site.refYear, this.modelManager.consVector, this.modelManager.derniere);
        this.plotDerniere();
    }

    updateRefYear(ref_year: number) {
        // to calculate predictions, we need the chosenVars of the refYear
        // and extract VarExpl of current year according to the chosen vars of the refYear
        // mMul with calced model of refYear
        this.site.updateRefYear(ref_year);
    }

    varLabel(key) {
        if (this.modelManager && this.modelManager.currYearCalcedRows && this.modelManager.currYearCalcedRows.length && this.modelManager.currYearCalcedRows[0].var_labels[key])
            return this.modelManager.currYearCalcedRows[0].var_labels[key];
        else return key;
    }

    plotDerniere() {
        this.modelManager.calcDerniere();
        const derniereCumul = H.arrayCumul(this.modelManager.derniere);
        const consRealCumul = H.arrayCumul(this.modelManager.consVector);

        // console.log("plotDerniere", derniereCumul, this.modelManager.derniere);
        this.chartOptions = {
            chart: {
                displayErrors: true,
                plotBackgroundColor: '#ffffff',
                plotShadow: false,
                borderColor: '#000000',
                borderWidth: 0,
                plotBorderWidth: K.lineWidthBase,
                reflow: false,
                width: 750,
                height: 500,
                style: {
                    fontFamily: "Roboto",
                    fontSize: "14px"
                }
            },
            plotOptions: {
                series: {
                    marker: {
                        enabled: false,
                        states: {
                            hover: {
                                enabled: false
                            }
                        }
                    }
                }
            },
            credits: {
                enabled: false
            },
            title: {
                text: undefined
            },
            lang: {
                thousandsSep: "'",
                numericSymbols: null,
            },
            xAxis: {
                lineWidth: 1,
                lineColor: '#000000',
                categories: ['Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Jun', 'Jul', 'Aoû', 'Sep', 'Oct', 'Nov', 'Déc'],
                gridLineColor: "#000000",
                gridLineWidth: 0.2,
                crosshair: true,
                labels: {
                    style: {
                        color: '#000000',
                        fontSize: '14px',
                    }
                }
            },
            yAxis: [{
                lineWidth: 1,
                lineColor: '#000000',
                tickAmount: 7,
                title: {
                    text: undefined
                },
                min: 0,
                labels: {
                    style: {
                        color: '#000000',
                        fontSize: '14px',
                    }
                }
            }, { // Secondary yAxis
                gridLineWidth: 0,
                opposite: true,
                min: -10000,
                max: 10000,
                title: {
                    text: 'Economie KWh',
                }
            }],
            accessibility: {
                enabled: false
            },
            series: [
                {
                    zIndex: 1,
                    yAxis: 0,
                    lineWidth: 2,
                    color: 'black',
                    name: "Valeurs réelles",
                    type: "line", tooltip: {
                        headerFormat: "{series.name}",
                        pointFormat: '<br>{point.category}: <b> {point.y:.2f}</b>',
                    },
                    data: consRealCumul || []
                },
                {
                    zIndex: 2,
                    yAxis: 0,
                    lineWidth: 2,
                    color: K.chartColors[0],
                    name: "Dernière",
                    type: "line", tooltip: {
                        headerFormat: "{series.name}",
                        pointFormat: '<br>{point.category}: <b> {point.y:.2f}</b>',
                    },
                    data: derniereCumul || []
                },
                {
                    zIndex: 0,
                    yAxis: 1,
                    tooltip: {
                        headerFormat: "{series.name}",
                        pointFormat: '<br>{point.category}, {point.y:.2f} ',
                    },
                    borderWidth: 0.5,
                    borderColor: '#666666',
                    color: '#f0f0f0',
                    name: "Economie",
                    type: "column",
                    data: this.modelManager.error || []
                }
            ]
        };
    }

    ngOnChanges(changes
                    :
                    SimpleChanges
    ) {
    }

}
