import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild, ViewContainerRef} from '@angular/core';
import {AppService} from "../../../../shared/services/app.service";
import {ApiService} from "../../../../shared/services/api.service";
import {Overlay} from "@angular/cdk/overlay";
import {SiteService} from "../../../../shared/services/site.service";
import {egretAnimations} from "../../../../shared/animations/egret-animations";
import {BaseComponent} from "../../../../shared/BaseComponent";
import {H} from "../../../../shared/helpers/H";
import {PdmDataRow} from "../../../../shared/models/PdmDataRow";
import {PdmRegDef} from "../../../../shared/models/PdmRegDef";
import * as MathJS from "mathjs";
import moment from "moment";
import {SiteReleveDelay} from "../../../../shared/models/models";

@Component({
    selector: 'app-sniffer',
    template: `
        <div class="flex-container full-width " fxLayout="row">
            <div fxLayout="row " class="numeric full-width" style="font-size: 14px" fxFlexAlign="start start">
                <mat-card class="p-4">
                    <h3 class="bloc-h3 color-blue">Configuration du lien EP</h3>
                    <table class="sniffer-table p-8">
                        <tr class="head">
                            <td>Key</td>
                            <td> Value</td>
                        </tr>
                        <ng-container *ngFor="let field of site.selectedSiteEpDataBase[dbKeyName]|keyvalue">
                            <tr *ngIf="field.key!=='releves'">
                                <td class="head">{{field.key}}</td>
                                <td> {{field.value}}  </td>
                            </tr>
                        </ng-container>
                    </table>
                </mat-card>
                <mat-card class="p-8">
                    <h3 class="bloc-h3" fxLayout="row">
                        <span>Données retirées de la ligne</span>
                        <span fxFlex> </span>
                        <button (click)="revertToInit()">
                            <mat-icon>restart_alt</mat-icon>
                        </button>
                    </h3>
                    <div>
                        Configuration site: Jour relevé = <b class="color-blue">[{{site.clientSite.releve_day}}]</b>
                    </div>
                    <table class="sniffer-table">
                        <tr class="head">
                            <td>Date</td>
                            <td>Rel. N°</td>
                            <td>Val.</td>
                            <td>Sem. N°</td>
                        </tr>
                        <ng-container *ngFor="let releve of originalSource.releves|keyvalue">
                            <tr [ngClass]="{odd:releve.value.num%2===0}">
                                <td class="head">
                                    {{releve.key}} {{getWeekDay(releve.key)}}
                                </td>
                                <td class="head">
                                    <span *ngIf="mapReleveToNum.has(releve.key)">
                                        {{convertIdToReleveNum(mapReleveToNum.get(releve.key))}}
                                    </span>
                                </td>
                                <ng-container>
                                    <td *ngFor="let d of releve.value|keyvalue">   {{d.value}}   </td>
                                </ng-container>
                            </tr>
                        </ng-container>

                    </table>
                </mat-card>
                <mat-card class="p-4" fxLayout="column" fxFlex="850px">
                    <h3 class="bloc-h3">Reduction Hebdo=>Mens</h3>
                    <div fxLayout="column">
                        <mat-card class="reduced-weeks-array p-4" *ngFor="let item of reducedToMonthlyReleves">
                            <div fxLayout="column">
                                <div fxLayout="row" style="height: 23px">
                                    <h3 class="bloc-h3 m-0" fxFlex="100px">Relevé n°: {{item.numReleve}}</h3>
                                    <div fxLayout="row" fxFlex="250px">
                                        <div class="weekly-val" *ngFor="let v of item.vals">
                                            {{v}}
                                        </div>
                                    </div>
                                    <div fxFlex="50px" class="color-blue font-weight-bold" style="padding: 0 8px;">
                                        <mat-icon style="font-size: 20px;padding: 0 8px;" inline="true">forward</mat-icon>
                                    </div>
                                    <div fxLayout="row" fxFlex="" class="weekly-vals-reduced">
                                        <div fxFlex="120px">
                                            <b>calcMode:</b> {{item.calcMode}}
                                        </div>
                                        <div fxFlex="60px">
                                            <b>Result:</b>
                                        </div>
                                        <div fxFlex="80px" class="align-right">
                                            {{item.result|number}} {{originalSource.unit}}
                                        </div>
                                        <div fxFlex class="color-blue font-weight-bold align-right border-left">
                                            {{item.lastDate}}
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </mat-card>
                    </div>
                    <div fxFlex></div>
                    <div fxFlex="50px" fxLayout="row">
                        <div fxFlex></div>
                        <button mat-raised-button color="primary" (click)="assignToFields()">
                            Assigner les valeurs aux relevés
                        </button>
                    </div>
                    <div>
                        <!--
                        <mat-card *ngFor="let item of siteCalendarValues" class="p-4 m-0 mb-8" fxLayout="row">
                            {{item.num}} - {{item.date}}
                            <b class="color-blue" *ngIf="item.edited===1"> -> EDITED</b>
                        </mat-card>
                        -->
                    </div>
                </mat-card>

            </div>
    `,
    styleUrls: ['./shared.component.scss'],
    animations: egretAnimations
})
export class SnifferComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {
    @Input('dbKeyName') dbKeyName: string;
    @Input('field') field: string;
    @Input('reg') reg: PdmRegDef;
    @Input('update') update: number;

    @Input('rows') rows: Map<string, PdmDataRow> = new Map<string, PdmDataRow>();
    @Output('cb') cb: EventEmitter<any> = new EventEmitter<any>();

    map = ['mon', 'wed', 'month_end', 'hebdo', 'daily'];
    mapFieldsToSnifferCol = {
        mon: 'i', wed: 'i', month_end: 'i', hebdo: 'd', daily: 'd'
    };
    originalSource: PdmEpLink = new PdmEpLink();
    reducedToMonthlyReleves: PdmWeeklyDataAccumulator[] = [];

    constructor(public myapp: AppService, public api: ApiService,
                public overlay: Overlay, public site: SiteService,) {
        super();
    }

    datesThatAreLastForMonth = [];
    datesThatAreFirstForMonth = [];

    public siteCalendarValues: SiteReleveDelay[] = [];

    mapReleveToNum: Map<string, number> = new Map<string, number>();

    ngOnInit(): void {
        this.revertToInit();
        this.site.generateCalendar(this.site.year);
        this.site.generateCalendar(this.site.year - 1);
        this.siteCalendarValues = Array.from(this.site.siteFinalCalendarMap.values())
            .sort((a, b) => a.id.localeCompare(b.id));
    }

    revertToInit() {
        this.originalSource = JSON.parse(JSON.stringify(this.site.selectedSiteEpDataBase[this.dbKeyName]));
        //console.log('revertToInit', this.originalSource, this.site.selectedSiteEpDataBase[this.dbKeyName]);
        this.reduce();
    }

    reduce() {
        this.datesThatAreLastForMonth = [];
        this.datesThatAreFirstForMonth = [];
        this.reducedToMonthlyReleves = [];
        let previousDate = '';
        let previousNum = 0;
        let lastIndex = 0;
        let weeklyDataAccumulator = new PdmWeeklyDataAccumulator(this.originalSource.calcMode);

        Object.keys(this.originalSource.releves).forEach((date, index) => {
            this.getReleveNum(date);
            const valObj = this.originalSource.releves[date];
            const releveNum = Number(this.mapReleveToNum.get(date).toString().substring(4, 6));
            if (releveNum === previousNum || previousNum === 0) {
                weeklyDataAccumulator.vals.push(valObj.val);
                weeklyDataAccumulator.lastDate = date;
            } else {
                weeklyDataAccumulator.calc();
                weeklyDataAccumulator.numReleve = previousNum;
                this.reducedToMonthlyReleves.push(weeklyDataAccumulator);
                weeklyDataAccumulator = new PdmWeeklyDataAccumulator(this.originalSource.calcMode);
                weeklyDataAccumulator.vals.push(valObj.val);
                weeklyDataAccumulator.lastDate = date;
            }

            if (previousNum !== releveNum && this.originalSource.freq === "Hebdo") {
                this.datesThatAreLastForMonth.push(previousDate);
                this.datesThatAreFirstForMonth.push(date);
            }

            previousDate = date;
            previousNum = releveNum;
            lastIndex = index;
        });

        if (weeklyDataAccumulator.size > 0) {
            weeklyDataAccumulator.calc();
            weeklyDataAccumulator.numReleve = previousNum;
            this.reducedToMonthlyReleves.push(weeklyDataAccumulator);
        }
    }

    convertIdToReleveNum(id: string) {
        return Number(id.toString().substring(4, 6));
    }

    getReleveNum(dateStr: string) {
        if (this.mapReleveToNum.has(dateStr))
            return;

        const listOfRelevesNums = [];
        const releveMoment = moment(dateStr, 'YYYY-MM-DD');
        this.site.siteFinalCalendarMap.forEach((calItem, id) => {
            const calItemMoment = moment(calItem.date, 'YYYY-MM-DD');
            const diff = releveMoment.diff(calItemMoment, 'days', true);
            if (diff <= 0 && diff > -40) {
                listOfRelevesNums.push(Number(calItem.id));
               // console.log("getReleveNum", "d" + dateStr, calItem, diff);
            }
        });
        this.mapReleveToNum.set(dateStr, Math.min(...listOfRelevesNums));
    }

    getWeekDay(dateStr) {
        const momOfdate = moment(dateStr, "YYYY-MM-DD");
        return momOfdate.format("ddd");
    }

    getDayName(date: Date) {
        if (date.getDay() === 0) return 'dimanche';
        if (date.getDay() === 1) return 'lundi';
        if (date.getDay() === 2) return 'mardi';
        if (date.getDay() === 3) return 'mercredi';
        if (date.getDay() === 4) return 'jeudi';
        if (date.getDay() === 5) return 'vendredi';
        if (date.getDay() === 6) return 'samedi';
    }

    assignToFields() {
        let lastDate = '';
        const rows = Array.from(this.rows.values());
        rows.sort((a, b) => {
            return (new Date(a.date).getTime() - new Date(b.date).getTime());
        });

        // iterate through stored rows and get last date
        rows.forEach((v, k) => {
            lastDate = v.date;
        });

        let prevDate = lastDate;
        this.reducedToMonthlyReleves.forEach((v, index) => {
            let newRow = new PdmDataRow({date: v.lastDate});
            const metas = ['EPLINK', this.originalSource.bdName, this.dbKeyName, this.originalSource.freq, this.originalSource.rowNum, this.originalSource.sheetName];
            if (this.rows.has(v.lastDate)) {
                newRow = this.rows.get(v.lastDate);
            } else {
                newRow.populateFirstNumDaysAndYear(prevDate);
                newRow.releve_num = v.numReleve;
                newRow.releve_year = v.yearReleve;
            }
            newRow.setFieldValue(this.reg, this.field, v.result, metas.join('|'));
            this.rows.set(v.lastDate, newRow);
            prevDate = v.lastDate;
        });

    }

    /*
    lifecycle hooks
     */
    ngOnChanges(changes: SimpleChanges) {


    }

    ngAfterViewInit() {

    }

    ngOnDestroy() {
    }
}

export class PdmWeeklyDataAccumulator {
    vals: number[] = [];
    calcMode: string;
    weekStart: number;
    weekEnd: number;
    result: number;
    numReleve: number;
    yearReleve: number;
    lastDate: string;
    lastDateTS: number;

    constructor(calcMode: string) {
        this.vals = [];
        this.calcMode = calcMode.toLowerCase().trim();
    }

    calc() {
        if (this.calcMode === "ind") {
            this.vals.forEach(v => this.result = v);
        }
        if (this.calcMode === "sum")
            this.result = MathJS.sum(this.vals);
        if (this.calcMode === "max")
            this.result = MathJS.max(this.vals);
        if (this.calcMode === "min")
            this.result = MathJS.min(this.vals);
        if (this.calcMode === "avg")
            this.result = MathJS.mean(this.vals);
        this.lastDateTS = Math.floor(new Date(this.lastDate).getTime() / 1000);

        const dateObj = new Date(this.lastDate);
        const daysFromYearBegin = H.getDayNum(dateObj);
        if (daysFromYearBegin <= 10 && daysFromYearBegin > 0) {
            this.yearReleve = dateObj.getFullYear() - 1;
        } else this.yearReleve = dateObj.getFullYear();
    }

    get size() {
        return this.vals.length;
    }
}

export class PdmEpLink {
    active: number;
    bdName: string;
    calcFact: number;
    calcMode: string;
    client: string;
    comptAddr: string;
    comptID: number;
    comptNumTxt: number;
    dateEnd: string;
    dateStart: string;
    freq: string;
    label: string;
    rowNum: number;
    sheetName: string;
    sourceTable: string;
    sourceType: string;
    statMens: string;
    unit: string;
    releves: any;
}
